<template>
  <div class="status-chip">
    <sk-chip :label="status.text"
             :color="status.class" />
  </div>
</template>

<script>
  import helpers from '@/helpers';

  export default {
    computed: {
      status() {
        return helpers.getJobInfo.jobStatusObjChip(this, this.params.data?.status);
      }
    },
  };
</script>
<style scoped>
.status-chip {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  padding: 0 5px;
}
</style>
