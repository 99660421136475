<script>
  import CId from '@/components/grid/cells/shared/CId';

  export default {
    extends: CId,
    methods: {
      // onIdClick() {
      //   if (this.params.data.type !== 'undefined') {
      //     this.$router.push(
      //       this.$makeRoute({name: 'ManagerOneCustomer',
      //                        params: {id: this.params.data?.uid}})
      //     );
      //   }
      // }
    }
  };
</script>

<style>
.customers__cont .job-id__number {
  margin: 0 5px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.customers__cont .job-id__number:hover {
  background-color: var(--color-primary-hover);
  text-decoration: underline;
}
</style>
