<script>
  import helpers from '@/helpers';
  import CStatusChip from '@/components/grid/cells/shared/CStatusChip';

  export default {
    extends: CStatusChip,
    computed: {
      type() { return this.params.data?.type || ''; },
      status() {
        return helpers.getAdminInfo.customerType(this, this.type);
      }
    },
  };
</script>
