<template>
  <section class="organization">
    <sk-input :placeholder="placeholder"
              :preselected-value="organizationSearchKey"
              @fieldvaluechanged="onInputBoxChanged" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        organizationSearchKey: '',
        requestTimer: ''
      };
    },
    computed: {
      placeholder() { return this.$gettext('Org number, name'); },
    },
    methods: {
      onInputBoxChanged(value) {
        if (value !== this.organizationSearchKey) {
          clearInterval(this.requestTimer);
          this.organizationSearchKey = value;
          this.requestTimer = setTimeout(() => {
            this.params.parentFilterInstance((instance) => {
              instance.onFloatingFilterChanged('contains', value);
            });
          }, 500);
        }
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.organizationSearchKey = '';
        }
      }
    }
  };
</script>

<style>
.organization .sk-input {
  margin-bottom: 0;
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
}

.organization {
  width: 100%;
  margin: auto;
}
</style>
