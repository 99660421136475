<template>
  <section class="session-type-filter">
    <sk-multiselect :items-list="sessionTypes"
                    :preselected-value="sessionType"
                    :default-value="sessionTypeDefaultValue"
                    @checkboxeschanged="onInputBoxChanged" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        sessionType: []
      };
    },
    computed: {
      sessionTypes() {
        return [
          {id: 'phone', name: this.$gettext('Phone')},
          {id: 'in_person', name: this.$gettext('In-person')},
          {id: 'video', name: this.$gettext('Video')}
        ];
      },
      sessionTypeDefaultValue() { return this.$gettext('Job type'); },

    },
    methods: {
      onInputBoxChanged(value) {
        this.sessionType = [...value];
        if (this.sessionType === '') {
          return;
        }
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('inList', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.sessionType = '';
        }
      }
    }
  };
</script>

<style>
.session-type-filter {
  width: 100%;
}

.session-type-filter .sk-select {
  background-color: #fff;
}

.session-type-filter .sk-select__value {
  color: #333;
  font-weight: normal;
  font-size: 13px;
}

.session-type-filter .sk-checkbox {
  justify-content: flex-start;
}

.session-type-filter .sk-checkbox__imitation {
  width: 13px;
  height: 13px;
}

.session-type-filter .sk-checkbox__text {
  display: none;
}

.session-type-filter .sk-select__list-item {
  display: flex;
  padding: 7px 3px;
}

.session-type-filter .sk-select__list-item::before {
  content: '';
  position: relative;
  left: 10px;
  width: 14px;
  height: 17px;
  padding: 3px;
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.session-type-filter .sk-select__list-item:nth-child(1)::before {
  background-image: url(~@assets/imgs/assignment_types/phone_icon_black.svg);
}

.session-type-filter .sk-select__list-item:nth-child(2)::before {
  background-image: url(~@assets/imgs/assignment_types/location_icon_black.svg);
}

.session-type-filter .sk-select__list-item:nth-child(3)::before {
  background-image: url(~@assets/imgs/assignment_types/video_icon_black.svg);
}
</style>
