(<template>
  <div class="customer">
    <div class="customer__wrapper">
      <div class="email-phone">
        <span v-if="phone"
              class="phone-icon"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip(phone, $event)"
              @click="copyToClipboard(phone)"></span>
        <span v-if="email"
              class="email-icon"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip(email, $event)"
              @click="copyToClipboard(email)"></span>
      </div>
      <p class="customer-name">{{ name || email }}</p>
    </div>
    <div v-if="showActions"
         :class="{'in-progress': actionBtnsProgress}"
         class="customer-actions is-progress-bar">
      <button v-if="isEnterpriseUser"
              class="edit-pencil-btn--rounded"
              @click="fetchEnterpriseDepartments"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip($gettext('Edit info'), $event)"></button>
      <button v-if="isEnterpriseUser || isPrivateUser"
              class="edit-enterprise-btn"
              @click="openEnterpriseEdit"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip(enterpriseBtnHint, $event)"></button>
      <button v-if="isEnterpriseUser"
              class="merge-customers-btn"
              @click="openMergeCustomers"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip($gettext('Merge customers'), $event)"></button>
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        actionBtnsProgress: false
      };
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      classes() { return this.propData?.classes; },
      name() { return this.propData?.name; },
      firstName() { return this.propData?.name?.split(' ')[0] || ''; },
      lastName() { return this.propData?.name?.split(' ')[1] || ''; },
      email() { return this.propData?.email; },
      phone() { return this.propData?.phone; },
      departments() { return this.propData?.departments || []; },
      isPrivateUser() { return this.propData?.type === 'private'; },
      isEnterpriseUser() { return this.propData?.type === 'enterprise'; },
      enterpriseBtnHint() {
        return this.isPrivateUser
          ? this.$gettext('Convert/upgrade to enterprise')
          : this.$gettext('Edit enterprise');
      },
      showActions() { return true; }
    },
    methods: {
      itemPosition(event) {
        const {top, left} = helpers.getElementPosition(event.target);
        return {top: (top) + 'px', left: (left + 20) + 'px'};
      },
      openTooltip(text, event) {
        this.$store.commit('TooltipRootStore/setTooltip', {
          data: {
            itemText: text,
            relativePosition: 'top-center'
          },
          absolutePosition: this.itemPosition(event)
        });
      },
      closeTooltip() { this.$store.dispatch('TooltipRootStore/closeTooltip'); },
      copyToClipboard(value) { navigator.clipboard.writeText(value); },
      openEnterpriseEdit() {
        if (this.isPrivateUser) this.openUpgradeBuyer();
        else this.openEnterpriseMove();
      },
      openEnterpriseMove() {
        this.$store.commit('AllCustomersStore/setSelectedCustomer', this.propData);
        this.$store.commit('ModalStore/setModal', {
          component: 'move-customer-enterprise-modal',
          classes: ['sk-modal--new', 'sk-modal--customer-actions'],
          width: 750,
          data: {
            title: this.$gettext('Move enterprise member from A to B'),
            context: this
          }
        });
      },
      openUpgradeBuyer() {
        this.$store.commit('AllCustomersStore/setSelectedCustomer', this.propData);
        this.$store.commit('ModalStore/setModal', {
          component: 'convert-upgrade-buyer-modal',
          classes: ['sk-modal--new', 'sk-modal--customer-actions'],
          width: 750,
          data: {
            title: this.$gettext('Convert/upgrade buyer to enterprise member'),
            context: this
          }
        });
      },
      openEditEmployee() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('Edit employee'),
            currentUser: {
              firstName: this.firstName,
              lastName: this.lastName,
              ...this.propData
            },
            hideAddDepartment: true,
            context: this,
            successHandlingCallbackName: 'refreshCustomerGrid'
          }
        });
      },
      openMergeCustomers() {
        this.$store.commit('AllCustomersStore/setSelectedCustomer', this.propData);
        this.$store.commit('ModalStore/setModal', {
          component: 'merge-customers-modal',
          classes: ['sk-modal--new', 'sk-modal--customer-actions'],
          width: 750,
          data: {
            title: this.$gettext('Merge Customers'),
            context: this
          }
        });
      },
      fetchEnterpriseDepartments() {
        const params = {
          enterpriseId: this.propData?.enterprise?.id
        };
        this.actionBtnsProgress = true;
        this.$store.dispatch('EnterpriseStore/getDepartments', params)
          .then(() => {
            this.actionBtnsProgress = false;
            this.openEditEmployee();
          })
          .catch(() => {
            this.actionBtnsProgress = false;
          });
      },
      refreshCustomerGrid() {
        this.$store.commit('AllCustomersStore/refreshCustomerGrid');
      }
    }
  };
</script>
<style scoped>
.customer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.customer__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.customer-name {
  overflow: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
}

.email-phone {
  margin-right: 8px;
  margin-left: 0;
}

.customer-actions {
  position: absolute;
  right: 0;
}
</style>
<style scoped src="@assets/css/phone_email_icon.css" />
