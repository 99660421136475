(<template>
  <div class="departments">
    <p class="overflow-ellipsis">{{ combinedDepartmentName }}</p>
  </div>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      classes() { return this.propData?.classes; },
      departmentsNames() { return this.propData?.departments; },
      combinedDepartmentName() {
        if (this.departmentsNames && this.departmentsNames.length) {
          let combined = this.departmentsNames[0].name;
          for (let i = 1; i < this.departmentsNames.length; i += 1) {
            combined = combined + ', ' + this.departmentsNames[i].name;
          }
          return combined;
        } else {
          return '-';
        }
      }
    }
  };
</script>
<style scoped>
.departments {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
</style>
