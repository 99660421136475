// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/assignment_types/phone_icon_black.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/assignment_types/location_icon_black.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/assignment_types/video_icon_black.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.session-type-filter {\n  width: 100%;\n}\n.session-type-filter .sk-select {\n  background-color: #fff;\n}\n.session-type-filter .sk-select__value {\n  color: #333;\n  font-weight: normal;\n  font-size: 13px;\n}\n.session-type-filter .sk-checkbox {\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n}\n.session-type-filter .sk-checkbox__imitation {\n  width: 13px;\n  height: 13px;\n}\n.session-type-filter .sk-checkbox__text {\n  display: none;\n}\n.session-type-filter .sk-select__list-item {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding: 7px 3px;\n}\n.session-type-filter .sk-select__list-item::before {\n  content: '';\n  position: relative;\n  left: 10px;\n  width: 14px;\n  height: 17px;\n  padding: 3px;\n  background-position: 50% 50%;\n  background-size: 100% auto;\n  background-repeat: no-repeat;\n}\n.session-type-filter .sk-select__list-item:nth-child(1)::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.session-type-filter .sk-select__list-item:nth-child(2)::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.session-type-filter .sk-select__list-item:nth-child(3)::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
