// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/job_grid/phone_black_icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/salita/job_grid/alternative_phone_icon.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/salita/job_grid/alternative_phone_grey_icon.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@assets/imgs/salita/job_grid/phone_grey_icon.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@assets/imgs/salita/job_grid/email_black_icon.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("@assets/imgs/salita/job_grid/email_grey_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "\n.email-phone[data-v-61aae03c] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-right: 8px;\n}\n.phone-icon[data-v-61aae03c],\n.alternative-phone-icon[data-v-61aae03c],\n.alternative-phone-icon--grey[data-v-61aae03c],\n.email-icon[data-v-61aae03c],\n.phone-icon--grey[data-v-61aae03c] {\n  width: 14px;\n  height: 17px;\n  background-position: 50% 50%;\n  background-size: 100% auto;\n  background-repeat: no-repeat;\n  cursor: pointer;\n}\n.phone-icon[data-v-61aae03c] {\n  margin-right: 5px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.alternative-phone-icon[data-v-61aae03c] {\n  width: 17px;\n  margin-right: 5px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.alternative-phone-icon--grey[data-v-61aae03c] {\n  width: 17px;\n  margin-right: 5px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.phone-icon--grey[data-v-61aae03c] {\n  width: 15px;\n  margin-right: 5px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.email-icon[data-v-61aae03c] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n.email-icon---grey[data-v-61aae03c] {\n  width: 15px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
