(<template>
  <div class="customers">
    <div class="customers__header">
      <div class="customers__header--left">
        <h1 class="customers__header-title">{{ $gettext('Customers') }}</h1>
      </div>
    </div>
    <div class="customers__cont">

      <!------- All Tabs ------->
      <div class="customers-tabs__cont">
        <multiselect-tab v-for="tab in allTabs"
                         :key="tab.tab"
                         :preselected-value="tab.selectedList"
                         :count="tab.count"
                         :with-avatar="tab.withAvatar"
                         :tab="tab" />
      </div>
      <!------- Grid Component ------->
      <ag-grid :column-defs="columnDefs"
               :grid-options="gridOptions"
               :fetch-data-func="fetchDataFunc"
               :grid-data="gridData"
               @interface="getChildInterface" />
    </div>
  </div>
</template>)

<script>
/* eslint-disable vue/no-unused-components */
// For Grid filter components
  import SessionTypeFilter from '@/components/grid/filters/shared/SessionTypeFilter';
  import CustomerNameFilter from '@/components/grid/filters/shared/TextFilter';
  import OrganizationFilter from '@/components/grid/filters/OrganizationFilter';
  import DepartmentFilter from '@/components/grid/filters/DepartmentFilter';
  import CustomerTypeFilter from '@/components/grid/filters/CustomerTypeFilter';
  import IdFilter from '@/components/grid/filters/IdFilter';
  import PageWithGridMixin from '@/mixins/PageWithGridMixin';
  // ---- Cells ----//
  import CId from '@/components/grid/cells/customer/CId';
  import CCustomerType from '@/components/grid/cells/customer/CCustomerType';
  import CName from '@/components/grid/cells/shared/CName';
  import COrganization from '@/components/grid/cells/customer/COrganization';
  import CDepartments from '@/components/grid/cells/customer/CDepartments';
  import CNextJob from '@/components/grid/cells/customer/CNextJob';
  import {mapState} from 'vuex';

  export default {
    components: {
      'customer-filter': CustomerNameFilter,
      'organization-filter': OrganizationFilter,
      'department-filter': DepartmentFilter,
      'session-type-filter': SessionTypeFilter,
      'customer-type-filter': CustomerTypeFilter,
      'id-filter': IdFilter,
      'c-id': CId,
      'c-customer-type': CCustomerType,
      'c-name': CName,
      'c-organization': COrganization,
      'c-departments': CDepartments,
      'c-next-job': CNextJob,
      'ag-grid': () => import('@/components/grid/GridComponent')
    },
    mixins: [PageWithGridMixin],
    data() {
      return {
        currentView: this.$route.query.view || 'active',
        currentPage: Number(this.$route.query.page) || 1,
        items: this.$store.state.filterItemsAmount || 10,
        filterParams: {
          id: {
            filter: this.$route.query.id || '',
            type: 'equals'
          },
        },
        ransackParams: {},
        fetchGridDataAction: 'AllCustomersStore/getCustomers',
        gridOptions: {
          pagination: true,
          suppressPaginationPanel: true,
          suppressMovableColumns: true,
          suppressColumnVirtualisation: false,
          paginationPageSize: this.$store.state.filterItemsAmount,
          paginationAutoPageSize: false,
          suppressExcelExport: true,
          suppressRowClickSelection: true,
          rowHeight: 65,
          popupParent: null,
          rowStyle: {'max-height': '58px'},
          rowModelType: 'infinite',
          // ------ cacheBlockSize Equal to pageSize no caching ------- //
          cacheBlockSize: this.$store.state.filterItemsAmount,
          maxBlocksInCache: 1,
          maxConcurrentDatasourceRequests: 2,
          domLayout: 'autoHeight',
          floatingFiltersHeight: 60
        },
        // ------- Tabs Data ------- //
        tabForUpdate: ''
      };
    },
    computed: {
      ...mapState('AllCustomersStore', {
        customersData: (state) => state.customersData || {},
        updateCustomerPageGrid: (state) => state.updateCustomerPageGrid || '',
      }),
      statistics() { return this.customersData.statistics; },
      allCount() { return this.statistics?.all; },
      columnDefs() {
        return [
          {
            headerName: this.$gettext('ID'),
            field: 'id',
            minWidth: 150,
            flex: 1,
            sortable: true,
            floatingFilterComponent: 'id-filter',
            ...this.textFilterNoMenu,
            cellRendererSelector: (params) => {
              return {
                component: 'c-id',
                params: params
              };
            }
          },
          {headerName: this.$gettext('Type'),
           field: 'customerType',
           flex: 1,
           floatingFilterComponent: 'customer-type-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-customer-type',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Name, email, phone'),
           field: 'lookupFields',
           sortable: true,
           flex: 2,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'customer-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-name',
               params: params
             };
           }},
          {
            headerName: this.$gettext('Org number, Name'),
            field: 'enterprise',
            flex: 2,
            sortable: true,
            ...this.textFilterNoMenu,
            floatingFilterComponent: 'organization-filter',
            cellRendererSelector: (params) => {
              return {
                component: 'c-organization',
                params: params
              };
            }
          },
          {headerName: this.$gettext('Department(s)'),
           field: 'departments',
           sortable: true,
           flex: 2,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'department-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-departments',
               params: params
             };
           }},
          {headerName: this.$gettext('Next job'),
           flex: 2,
           field: 'nextJob',
           cellRendererSelector: (params) => {
             return {
               component: 'c-next-job',
               params: params
             };
           }},
        ];
      },
      gridData() {
        return {
          page: this.currentPage,
          items: this.items,
          filterParams: this.filterParams,
          ransackParams: this.tabRansackParams
        };
      },
      tabRansackParams() {
        switch (this.currentView) {
          case 'all':
            return {};
        }
      },
      isAllView() { return this.currentView === 'all'; },
      allTabs() {
        return [
          {
            tab: 'all',
            isActive: this.isAllView,
            title: this.$gettext('All'),
            count: this.allCount,
            onClick: this.setCurrentView
          }
        ];
      }
    },
    watch: {
      updateCustomerPageGrid() {
        this.updateGridData();
      }
    },
    beforeRouteEnter(to, from, next) {
      if (to.query.view == {} || to.query.view == null || to.query.view == undefined) {
        next((vm) => {
          return {path: to.path, query: {view: 'all', page: 1, items: vm.$store.state.filterItemsAmount}};
        });
      }
      next();
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('AllCustomersStore/clearStore');
      next();
    },
    childInterface: {
      updateData: () => {}
    },
  };
</script>
<style scoped>
.customers {
  margin: 10px 20px;
}

.customers__cont {
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 5%);
}

.customers-tabs__cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
  border-bottom: 1px solid #d3d5de;
}

.customers__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.customers__header--left {
  display: flex;
}

.customers__header-title {
  font-size: 24px;
}
</style>
