(<template>
  <div class="organization">
    <p class="overflow-ellipsis">{{ organizationName || '-' }}</p>
  </div>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      classes() { return this.propData?.classes; },
      organizationName() { return this.propData?.enterprise?.name; }
    }
  };
</script>
<style scoped>
.organization {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
</style>
